<template>
  <section class="lista-apolice">
    <div class="m-1 mr-4 px-0">
      <breadcrumb title="Apólices" actualPage="Lista de apólices" />
    </div>

    <div
      class="grid grid-cols-3 mt-4 mb-6 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2"
    >
      <template v-if="!perfilHelpers.isInsurance()">
        <location-dropdown></location-dropdown>
      </template>

      <filtro ref="filtro" titleName="Apólices" :isFilter="filtro">
        <div class="px-4">
          <vs-row class="pt-0">
            <h5 class="title-group pt-0 pb-2">Dados da apólice</h5>
            <vs-col vs-lg="6" vs-sm="12">
              <vs-input
                size="large"
                label="Número da apólice"
                v-model="filter.policyNumber"
              />
            </vs-col>

            <vs-col class="container-element  pt-6" vs-lg="6" vs-sm="12">
              <p class="label-content label">Status da a pólice</p>
              <v-select
                placeholder="Selecionar"
                class="w-full"
                :show-no-options="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="selectedPolicyStatusOption"
                @input="setPoliceStatusOption(selectedPolicyStatusOption)"
                label="name"
                :options="policyStatusOptionsMixin"
                size="large"
              >
              </v-select>
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col class="container-element  pt-6" vs-lg="6" vs-sm="12">
              <p class="label-content label">Data inicial</p>
              <datepicker
                :language="language"
                format="dd/MM/yyyy"
                v-model="filter.startDate"
              ></datepicker>
            </vs-col>

            <vs-col class="container-element  pt-6" vs-lg="6" vs-sm="12">
              <p class="label-content label">Data final</p>
              <datepicker
                :language="language"
                format="dd/MM/yyyy"
                v-model="filter.endDate"
              ></datepicker>
            </vs-col>
          </vs-row>

          <vs-row v-if="perfilHelpers.isInsurance()">
            <vs-col class="flex justify-between">
              <vs-checkbox v-model="filter.enableToBigRisk"
                >Grande risco
              </vs-checkbox>

              <vs-checkbox v-model="filter.enableToMonitoring"
                >Monitoramento
              </vs-checkbox>

              <vs-checkbox v-model="filter.enableToSteppin"
                >Retoma de objeto (Step-in)
              </vs-checkbox>
            </vs-col>
          </vs-row>

          <hr class="mt-6 opacity-25" />
          <vs-row>
            <h5 class="title-group pb-2">Dados dos envolvidos</h5>
            <vs-col vs-lg="8" vs-sm="12">
              <vs-input
                size="large"
                label="Nome do tomador"
                v-model="filter.policyHolderName"
              />
            </vs-col>

            <vs-col vs-lg="4" vs-sm="12">
              <vs-input
                size="large"
                label="Documento do tomador"
                v-model="filter.policyHolderDocument"
              />
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col vs-lg="8" vs-sm="12">
              <vs-input
                size="large"
                label="Nome do segurado"
                v-model="filter.insuredName"
              />
            </vs-col>

            <vs-col vs-lg="4" vs-sm="12">
              <vs-input
                size="large"
                label="Documento do segurado"
                v-model="filter.insuredDocument"
              />
            </vs-col>
          </vs-row>

          <vs-row v-if="perfilHelpers.isInsurance()">
            <vs-col vs-lg="8" vs-sm="12">
              <vs-input
                size="large"
                label="Nome do corretor"
                v-model="filter.brokerName"
              />
            </vs-col>

            <vs-col vs-lg="4" vs-sm="12">
              <vs-input
                size="large"
                label="Documento do corretor"
                v-model="filter.brokerDocument"
              />
            </vs-col>
          </vs-row>

          <div class="flex flex-row-reverse mt-4">
            <vs-button
              @click="listarApolices(true)"
              color="primary"
              type="filled"
              class="ml-4"
            >
              Aplicar filtro
            </vs-button>

            <vs-button
              class="button-default"
              type="filled"
              icon="clear"
              @click="limparFiltros()"
            >
              Limpar filtro
            </vs-button>
          </div>
        </div>
      </filtro>

      <vs-button
        v-if="
          perfilHelpers.checkPermission([
            perfilHelpers.menuFuncionalitiesEnum.SOLICITAR_ENDOSSO
          ])
        "
        @click="showBuscarApolice()"
        >Endosso</vs-button
      >
    </div>
    <div class="lista-apolices" v-if="apolices.length > 0">
      <div class="apolice" v-for="(item, index) in apolices" :key="index">
        <div class="topo">
          <div class="linha">
            <p>
              Apólice:
              <b> {{ item.PolicyNumber ? item.PolicyNumber : "S/N" }}</b>
              <i
                class="copy"
                role="button"
                @click="
                  copyToClipboard(item.PolicyNumber ? item.PolicyNumber : 'S/N')
                "
              >
                <onPointIcon :icon="'copy'" />
              </i>
            </p>
            <p>
              Proposta: <b> {{ (item || {}).ProposalNumber }}</b
              ><i
                v-if="item.ProposalNumber"
                class="copy"
                role="button"
                @click="copyToClipboard(item.ProposalNumber)"
              >
                <onPointIcon :icon="'copy'" />
              </i>
            </p>
            <p v-if="item.CompanyReferenceNumber">
              Ref. da Seguradora:
              <b> {{ (item || {}).CompanyReferenceNumber }}</b>

              <i
                v-if="item.CompanyReferenceNumber"
                class="copy"
                role="button"
                @click="copyToClipboard(item.CompanyReferenceNumber)"
              >
                <onPointIcon :icon="'copy'"
              /></i>
            </p>
          </div>
          <div class="botoes">
            <div class="baixar">
              <el-dropdown
                split-button
                type="primary"
                v-if="
                  item.ArchivePolicyId !== 0 ||
                    item.ArchiveLastEndorsementId !== 0 ||
                    (perfilHelpers.isInsurance() && item.ArchivePolicyId !== 0)
                "
              >
                Baixar arquivos
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="item.ArchivePolicyId !== 0"
                    @click.native="
                      downloadApoliceClick(item.RelatedProposalUniqueId)
                    "
                  >
                    <p class="text-base px-6 py-2">
                      <span>Download da apólice (original)</span>
                    </p></el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="item.ArchiveLastEndorsementId !== 0"
                    @click.native="
                      downloadUltimoEndossoClick(item.RelatedProposalUniqueId)
                    "
                  >
                    <p class="text-base px-6 py-2">
                      <span>Download do endosso (mais recente)</span>
                    </p></el-dropdown-item
                  >

                  <el-dropdown-item
                    v-if="
                      perfilHelpers.isInsurance() && item.ArchivePolicyId !== 0
                    "
                    @click.native="
                      downloadLaudoEmissaoClick( item.OriginalProposalUniqueId
                      )
                    "
                  >
                    <p class="text-base px-6 py-2">
                      <span>Download do laudo de emissão (original)</span>
                    </p></el-dropdown-item
                  >

                  <el-dropdown-item
                    v-if="item.HasInstallment"
                    @click.native="boletoApolice(item)"
                  >
                    <p class="text-base px-6 py-2">
                      <span> Download do(s) boleto(s) (original)</span>
                    </p></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown split-button type="primary" :disabled="true" v-else>
                Baixar arquivos
                <el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="detalhar">
              <el-dropdown
                split-button
                type="primary"
                @click="goToPolicy(item.PolicyUniqueId)"
              >
                Detalhes da apólice

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="
                      perfilHelpers.checkPermission([
                        perfilHelpers.menuFuncionalitiesEnum.SOLICITAR_ENDOSSO
                      ]) &&
                        $enums.PolicyStatus.Emitida == item.PolicyStatusId &&
                        validBroker(item)
                    "
                    divider
                    @click.native="showSolicitacaoEndosso(item.PolicyUniqueId)"
                  >
                    <p class="text-base px-6 py-2">
                      <span>Solicitar endosso </span>
                    </p></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button
                type="primary"
                round
                v-else
                button
                @click="goToPolicy(item.PolicyUniqueId)"
              >
                Detalhes da apólice</el-button
              > -->
            </div>
          </div>
        </div>
        <div class="corpo">
          <div class="item first">
            <p>
              Tomador: <b> {{ (item || {}).PolicyHolderName }}</b>
              <i
                v-if="item.PolicyHolderName"
                class="copy"
                role="button"
                @click="copyToClipboard(item.PolicyHolderName)"
                ><onPointIcon :icon="'copy'"
              /></i>
            </p>
            <p>
              <strong style="color: transparent"> Tomador:</strong>
              {{ (item || {}).PolicyHolderDocument | maskCpfCnpj }}
              <i
                class="copy"
                role="button"
                @click="copyToClipboard(item.PolicyHolderDocument)"
                ><onPointIcon :icon="'copy'"
              /></i>
            </p>
            <p>
              Segurado: <b> {{ (item || {}).InsuredName }}</b>
              <i
                v-if="item.InsuredName"
                class="copy"
                role="button"
                @click="copyToClipboard(item.InsuredName)"
                ><onPointIcon :icon="'copy'"
              /></i>
            </p>
            <p>
              <strong style="color: transparent"> Segurado:</strong>

              {{ (item || {}).InsuredDocumentMask }}
              <!-- {{ (item || {}).InsuredDocumentMask }} -->
              <i
                class="copy"
                role="button"
                @click="copyToClipboard(item.InsuredDocument)"
                ><onPointIcon :icon="'copy'"
              /></i>
            </p>
            <p v-if="item.BrokerName">
              Corretor:<b> {{ (item || {}).BrokerName }}</b>
            </p>
          </div>
          <div class="item">
            <p>
              Modalidade:<b> {{ (item || {}).Modality }}</b>
            </p>
            <p>
              IS: <b> {{ (item || {}).InsuredAmountValue | maskCurrency }}</b>
            </p>
            <p>
              Prêmio: <b> {{ (item || {}).PremiumValue | maskCurrency }}</b>
            </p>
            <p>
              Contém endosso?<b>
                {{ (item || {}).EndorsementCount > 0 ? "Sim" : "Não" }}</b
              >
            </p>
          </div>
          <div class="item">
            <p>
              Emissão:
              <b>
                {{
                  item.CreateDate ? $utils.dateToDDMMYYYY(item.CreateDate) : "-"
                }}</b
              >
            </p>
            <p>
              Vigência:
              <b>
                {{
                  item.StartDate ? $utils.dateToDDMMYYYY(item.StartDate) : "-"
                }}</b
              >
              Até
              <b>
                {{
                  item.EndDate ? $utils.dateToDDMMYYYY(item.EndDate) : "-"
                }}</b
              >
            </p>

            <p>
              Status:
              <vs-button
                class="status"
                type="filled"
                size="small"
                :color="item.PolicyStatusId | setLabelStatusPolicy"
              >
                {{ getStatus(item.PolicyStatusId) }}
              </vs-button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
      <p><span class="material-icons text-6xl">description</span></p>
      <p>Você ainda não possui nenhuma apólice cadastrada.</p>
    </div>
    <vs-pagination :total="numberOfPages" v-model="currentPage"></vs-pagination>

    <vs-popup
      title="Buscar apólice"
      :active.sync="popupEndosso"
      v-if="listaTiposSolicitacaoEndosso && listaTiposSolicitacaoEndosso.length"
    >
      <div class="vx-row mt-8 mb-8 ml-3 mr-3">
        <div class="vx-col w-full">
          <p class="font-bold pt-3 pb-3 w-full">Número apólice</p>
          <vs-input
            size="large"
            label-placeholder="Número da apólice"
            class="w-full md:w-3/5 md:pr-3"
            v-model="filter.policyNumber"
          />
        </div>
        <div class="flex flex-row-reverse mt-4">
          <vs-button
            @click="buscarApolices(true)"
            color="primary"
            type="filled"
            class="ml-4"
          >
            Buscar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      title="Solicitação de endosso"
      :active.sync="popupSolicitacaoEndosso"
      v-if="listaTiposSolicitacaoEndosso && listaTiposSolicitacaoEndosso.length"
    >
      <div class="vx-row mt-8 mb-8 ml-3 mr-3">
        <div class="vx-col w-full">
          <p class="font-bold pt-3 pb-3 w-full">Tipo de solicitação</p>
          <div class="w-full">
            <ValidationProvider
              name="tipo da solicitacao"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="Name"
                class="w-full"
                :appendToBody="true"
                :options="listaTiposSolicitacaoEndosso"
                :show-no-options="false"
                :show-no-results="false"
                v-model="selectedTipoSolicitacaoEndosso"
                placeholder="Selecione um tipo da solicitação do endosso"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="vw-row ml-3 mr-3">
        <div class="vx-col w-full text-right">
          <vs-button
            :disabled="!(selectedTipoSolicitacaoEndosso || {}).Id"
            @click="goToSolicitacaoEndosso()"
            class="button-primary"
          >
            Prosseguir
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <listar-parcelas-popup
      :parcelasModal="parcelasModal"
      :propostaUniqueId="policyId"
      @fecharParcelasModal="parcelasModal = false"
      :autoLoad="false"
    />
  </section>
</template>

<script>
import vSelect from "vue-select";
import Breadcrumb from "../../layouts/components/breadcrumb/Breadcrumb";
import LocationDropdown from "../../components/location-dropdown/LocationDropdown";
import Filtro from "../../components/Filtro/Filtro";
import onPointIcon from "@/components/onPointIcons";
import Datepicker from "vuejs-datepicker";
import optionsStaticMixin from "../../mixins/optionsStaticMixin";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapActions, mapMutations } from "vuex";
import ListarParcelasPopup from "@/views/propostas/components/visualizacao/ListarParcelasPopup.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import environment from "@/environment";

export default {
  name: "lista-apolices",
  components: {
    ListarParcelasPopup,
    Breadcrumb,
    LocationDropdown,
    Filtro,
    Datepicker,
    onPointIcon,
    "v-select": vSelect
  },
  mixins: [optionsStaticMixin],
  data() {
    return {
      language: ptBR,
      currentPage: 1,
      rowsPerPage: 10,
      endossoAnexosModal: false,
      endossoAnexos: [],
      endossoAnexosArquivos: [],
      selectedPolicyStatusOption: undefined,
      filter: {
        policyNumber: "",
        policyHolderName: "",
        policyHolderDocument: "",
        insuredName: "",
        insuredDocument: "",
        brokerName: "",
        brokerDocument: "",
        startDate: "",
        endDate: "",
        statusId: ""
      },
      selectedPolicyUniqueId: undefined,
      popupSolicitacaoEndosso: false,
      popupEndosso: false,
      listaTiposSolicitacaoEndosso: [],
      selectedTipoSolicitacaoEndosso: undefined,
      perfilHelpers: PerfilHelpers,
      parcelasModal: false,
      filtro: false,
      policyId: null,
      Essor: false
    };
  },
  created() {
    var portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    var Essor = parseInt(environment.CompanyId.Essor);
    this.Essor = portalConfig.CompanyId == Essor ? true : false;
    this.$appInsights.trackPageView({
      name: "apolices",
      url: window.location.href
    });
  },
  mounted() {
    this.listarApolices();
    this.loadEndorsementType();
  },
  computed: {
    ...mapGetters("apolice-module", ["searchApolicesResult", "apolices"]),
    ...mapGetters("endosso-module", ["endossoList"]),
    ...mapGetters("auth", ["userInfo"]),
    numberOfPages: {
      get() {
        return (this.searchApolicesResult || {}).NumberOfPages || 0;
      }
    }
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.listarApolices();
    }
  },
  methods: {
    ...mapActions("endosso-module", [
      "getEndorsementAttachmentsList",
      "getEndorsementRequestDocumentArchives",
      "downloadEndorsementArchive",
      "getEndorsementType",
      "validateCanRequestEndorsementType"
    ]),
    ...mapActions("apolice-module", [
      "searchApolices",
      "downloadApolice",
      "downloadUltimoEndosso",
      "searchApolice"
    ]),
    ...mapActions("propostas-module", [
      "downloadBoleto",
      "downloadLaudoEmissao"
    ]),
    ...mapActions("cadastro-corretora", ["validarCorretoraAtiva"]),
    ...mapMutations("endosso-module", ["updatehasReason"]),

    async copyToClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$notify({
          title: "Sucesso",
          message: mytext + ", copiado!",
          type: "success",
          position: "bottom-right"
        });
      } catch ($e) {}
    },
    validBroker(row) {
      if (row.IsNominated && !row.IsNominatedToThisBroker) {
        return false;
      } else {
        return true;
      }
    },

    goToPolicy(policyUniqueId) {
      this.$router.push({
        name: "detalhar-apolice",
        params: { policyUniqueId }
      });
    },

    listarApolices(resetPage) {
      this.filtro = !this.filtro;
      if (resetPage) this.currentPage = 1;

      this.$onpoint.loading(() => {
        const params = {
          isInsurance: this.perfilHelpers.isInsurance(),
          filter: {
            ...this.filter,
            policyHolderDocument: this.$utils.removeSpecialChars(
              this.filter.policyHolderDocument
            ),
            insuredDocument: this.$utils.removeSpecialChars(
              this.filter.insuredDocument
            ),
            brokerDocument: this.$utils.removeSpecialChars(
              this.filter.brokerDocument
            )
          },
          currentPage: this.currentPage,
          rowsPerPage: this.rowsPerPage
        };

        return this.searchApolices(params).catch(err => {
          this.$onpoint.errorModal(err.response.data.Errors);
        });
      });
    },

    buscarApolices(resetPage) {
      this.$vs.loading();
      if (resetPage) this.currentPage = 1;
      this.$onpoint.loading(() => {
        const params = {
          isInsurance: this.perfilHelpers.isInsurance(),
          filter: {
            ...this.filter,
            policyHolderDocument: this.$utils.removeSpecialChars(
              this.filter.policyHolderDocument
            ),
            insuredDocument: this.$utils.removeSpecialChars(
              this.filter.insuredDocument
            ),
            brokerDocument: this.$utils.removeSpecialChars(
              this.filter.brokerDocument
            )
          },
          currentPage: this.currentPage,
          rowsPerPage: this.rowsPerPage
        };

        return this.searchApolice(params)
          .then(apl => {
            this.$vs.loading.close();
            this.popupEndosso = false;

            if (apl.NumberOfRows < 1) {
              this.$onpoint.errorModal("Apólice não encontrada.");
              return;
            }

            if (apl.Data[0].PolicyStatusId != 2) {
              this.$onpoint.errorModal(
                "Desculpe, mas não é possível solicitar um endosso pois a aólice não está emitida."
              );
              return;
            }

            this.showSolicitacaoEndosso(apl.Data[0].PolicyUniqueId);
          })
          .catch(err => {
            this.$vs.loading.close();
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    setPoliceStatusOption(option) {
      this.filter.statusId = option.id;
    },

    limparFiltros() {
      this.filter = {
        policyNumber: "",
        policyHolderName: "",
        policyHolderDocument: "",
        insuredName: "",
        insuredDocument: "",
        brokerName: "",
        brokerDocument: "",
        startDate: "",
        endDate: "",
        statusId: ""
      };
      this.selectedPolicyStatusOption = undefined;
      this.currentPage = 1;
      this.listarApolices();
    },
    boletoApolice(item) {
      this.policyId = item.OriginalProposalUniqueId;
      this.parcelasModal = true;
    },
    downloadApoliceClick(propostaUniqueId) {
      let payload = {
        propostaUniqueId: propostaUniqueId,
        endorsement: false
      };
      this.$onpoint.loading(() => {
        return this.downloadApolice(payload)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    downloadUltimoEndossoClick(propostaUniqueId) {
      this.$onpoint.loading(() => {
        return this.downloadUltimoEndosso(propostaUniqueId)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async downloadBoletoClick(propostaUniqueId) {
      await this.$onpoint.loading(async() => {
        return await this.downloadBoleto(propostaUniqueId)
          .then(linksToDownload => {
            //deve-se desabilitar o bloqueio de popups no navegador, senão apenas o primeiro item será baixado
            linksToDownload.forEach(element => {
              window.open(element);
            });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async downloadLaudoEmissaoClick(propostaUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.downloadLaudoEmissao(propostaUniqueId)
          .then(urlToDownloadIssuanceReport => {
            window.open(urlToDownloadIssuanceReport, "_self");
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    getPercent(startDate, endDate, statusId) {
      if (!endDate || !startDate || statusId == 3) return 100;

      const timeNow = new Date().getTime();
      const timeStart = new Date(startDate).getTime();
      const timeEnd = new Date(endDate).getTime();

      if (timeStart > timeNow || statusId == 1) return 0;
      else if (timeNow > timeEnd) return 100;
      else {
        return ((timeEnd - timeNow) * 100) / timeEnd;
      }
    },

    getPercentColor(percent) {
      if (percent < 33) return "success";
      else if (percent >= 33 && percent < 66) return "warning";
      else return "danger";
    },

    getStatus(statusId) {
      const status = this.policyStatusOptionsMixin.filter(status => {
        if (status.id == statusId) return status;
      });
      if (!status || status.length == 0) return "Não definido";

      return status[0].name;
    },

   async goToSolicitacaoEndosso() {
      var request = {
        policyUniqueId: this.selectedPolicyUniqueId,
        type:
          this.selectedTipoSolicitacaoEndosso.Id == 2
            ? "ALTERACAO"
            : "CANCELAMENTO"
      };

      return await this.$onpoint.loading(async() => {
        return  await this.validateCanRequestEndorsementType(request)
          .then(response => {
            this.updatehasReason(response.endorsementReason);
            if (this.selectedTipoSolicitacaoEndosso.Id == 2) {
              this.$router.push({
                name: "endosso-alteracao",
                params: {
                  policyUniqueId: this.selectedPolicyUniqueId,
                  typeId: this.selectedTipoSolicitacaoEndosso.Id
                }
              });
            } else if (this.selectedTipoSolicitacaoEndosso.Id == 1)
              this.$router.push({
                name: "endosso-cancelamento",
                params: {
                  policyUniqueId: this.selectedPolicyUniqueId,
                  typeId: this.selectedTipoSolicitacaoEndosso.Id
                }
              });
          })
          .catch(error => {
            this.popupSolicitacaoEndosso = false;
            this.selectedTipoSolicitacaoEndosso = undefined;
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    handleChangePage(page) {
      this.filter.currentPage = page;
      this.listarApolices();
    },

    showBuscarApolice() {
      this.popupEndosso = true;
    },
    disclamerbuscarApolices(boolean) {
      this.$confirm(
        ' Tem certeza que deseja cancelar este documento? Após a confirmação da emissão do endosso de cancelamento não será possível a sua reversão, ou seja, não será possível reativar apólices ou endossos cancelados."',
        "Atenção!",
        {
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar"
        }
      )
        .then(() => {
          this.buscarApolices(boolean);
        })
        .catch(() => {
          console.log("cancelou");
        });
    },
    disclamerSolicitacaoEndosso(policyUniqueId) {
      this.$confirm(
        ' Tem certeza que deseja cancelar este documento? Após a confirmação da emissão do endosso de cancelamento não será possível a sua reversão, ou seja, não será possível reativar apólices ou endossos cancelados."',
        "Atenção!",
        {
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar"
        }
      )
        .then(() => {
          this.showSolicitacaoEndosso(policyUniqueId);
        })
        .catch(() => {
          console.log("cancelou");
        });
    },
    showSolicitacaoEndosso(policyUniqueId) {
      if ((this.listaTiposSolicitacaoEndosso || []).length == 0) {
        this.$onpoint.errorModal(
          "Desculpe, mas não é possível solicitar um endosso pois não existe nenhum tipo habilitado."
        );
        return;
      }

      this.selectedPolicyUniqueId = policyUniqueId;
      this.popupSolicitacaoEndosso = true;
      this.selectedTipoSolicitacaoEndosso = undefined;
    },

    async loadEndorsementType() {
     await this.$onpoint.loading(async () => {
        return await this.getEndorsementType()
          .then(response => {
            this.listaTiposSolicitacaoEndosso = response;
            if (this.perfilHelpers.isBroker())
              return this.validarCorretoraAtiva()
                .then(data => {
                  if (!data.IsActive) this.listaTiposSolicitacaoEndosso = [];
                })
                .catch(err => {
                  this.$onpoint.errorModal(err.response.data.Errors);
                });
            else this.listaTiposSolicitacaoEndosso = [];
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";
.filter-title {
  color: #636363;
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 10px;
}
.table-list {
  margin-top: -32px;

  .input-currency {
    border: none;
    background: transparent;
    color: inherit;
  }

  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 25px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.1em !important;
      width: 300px;
      border: 0 !important;
      height: 47px;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.btn-action-policy-options {
  ::v-deep .vs-dropdown--menu {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.status {
  width: 11em !important;
  height: 35px;
  padding: 0.5em 1em !important;
  pointer-events: none;
}

.vs-progress--background {
  min-width: 100px;
  padding: 2px;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.vs-table--tbody-table
  .tr-values.hoverFlat:not(.activeEdit):not(.is-expand):hover {
  opacity: unset;
}

.visualizar-endosso {
  width: 3px !important;
  height: 3px !important;
  margin-left: 7px;
  transform: scale(1.5);
}
</style>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 99999999;
}

#filtro-open,
.filtro-open {
  .vs__dropdown-toggle {
    border: 1px solid rgba(60, 60, 60, 0.26);
    box-shadow: 0 0;

    &:focus-within {
      border: 1px solid rgba(var(--vs-primary), 1) !important;
    }
  }

  .vdp-datepicker input {
    height: 47px !important;

    &:focus {
      border: 1px solid rgba(var(--vs-primary), 1) !important;
    }
  }
}
.alert {
  color: #ff9f43;
}
</style>
